import { Box, makeStyles, TextField as MuiTextField, TextFieldProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import clsx from "clsx";
import { ChangeEventHandler, FocusEventHandler, MutableRefObject, Ref, useCallback, useRef, VFC } from "react";
import {
  DATE_DISPLAY_FORMAT,
  getFormattedDisplayDate,
  isDateNowish,
  isDateNowOrEarlierThisWeek,
  isDateToday,
  roundTimeToChunk,
} from "../../utils/dates";
import { DateTimePicker } from "./DateTimePicker";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
  }),
  {
    classNamePrefix: "DateControl",
  }
);

export type DateControlJSSClassKey = keyof ReturnType<typeof useStyles>;

const parse = (value: string, refDate?: Date, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;
  return parseDate(value, refDate, { ...options });
};

/**
 * Handler for DateControl date changes.  String if valid or empty date, undefined if invalid date.
 */
export type DateControlDateChangeHandler = (dateStr: string | undefined) => void | Promise<void>;

export type DateControlProps = {
  classes?: Partial<ClassNameMap<DateControlJSSClassKey>>;
  name?: string;
  format?: string;
  refDate?: Date;
  chronoOptions?: ChronoParsingOption;
  defaultDatePickerValue?: string;
  defaultDatePickerHour?: ((date: Date) => Date) | number;
  className?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  inlinePicker?: boolean;
  staticPicker?: boolean;
  dayMode?: boolean;
  value?: string;
  inputRef?: Ref<HTMLInputElement>;
  TextFieldProps: TextFieldProps;
  onDateChange?: DateControlDateChangeHandler;
};

export const DateControl: VFC<DateControlProps> = ({
  format = DATE_DISPLAY_FORMAT,
  refDate,
  chronoOptions,
  defaultDatePickerValue,
  defaultDatePickerHour,
  className,
  disablePast,
  disableFuture,
  inlinePicker,
  staticPicker,
  dayMode,
  onDateChange,
  TextFieldProps,
  inputRef,
  classes: extClasses,
  name,
  value,
}) => {
  const anchorElRef = useRef<HTMLInputElement | undefined>();
  const { onChange, onBlur, ...textFieldPropsRest } = TextFieldProps || {};

  const classes = useStyles({
    classes: extClasses,
  });

  const applyDefaultHour = useCallback(
    (date: Date): Date => {
      date = new Date(date);

      switch (typeof defaultDatePickerHour) {
        case "function":
          date = defaultDatePickerHour(date);
          break;
        case "number":
          date.setHours(defaultDatePickerHour);
          date.setMinutes(0);
          date.setSeconds(0);
          break;
      }

      return date;
    },
    [defaultDatePickerHour]
  );

  const parseFn = useCallback(
    (value: string) => {
      let parsed: Date | undefined;

      if (!!chronoOptions?.forwardDate) {
        const preParse = parse(value);
        parsed = !!preParse && isDateNowOrEarlierThisWeek(preParse) ? new Date() : parse(value, refDate, chronoOptions);
      } else {
        parsed = parse(value, refDate, chronoOptions);
      }

      if (parsed) parsed = applyDefaultHour(parsed);

      return parsed;
    },
    [chronoOptions, applyDefaultHour, refDate]
  );

  const formatFn = useCallback(
    (date: Date, dayMode: boolean) => getFormattedDisplayDate(date, format, dayMode),
    [format]
  );

  const getDisplayDate = useCallback(
    (date: Date) => {
      if (!!dayMode) {
        return isDateToday(date) ? "Today" : formatFn(date, true);
      } else {
        return isDateNowish(date) ? "now" : formatFn(roundTimeToChunk(date), false);
      }
    },
    [dayMode, formatFn]
  );

  const handleDateChanged = useCallback(
    async (date: string) => {
      const parsed = parseFn(date);

      // input is a valid date
      if (onDateChange) {
        const display = parsed && getDisplayDate(parsed);
        return onDateChange(display);
      }
    },
    [getDisplayDate, onDateChange, parseFn]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      await handleDateChanged(e.target.value);
      onChange?.(e);
    },
    [handleDateChanged, onChange]
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    async (e) => {
      await handleDateChanged(e.target.value);
      onBlur?.(e);
    },
    [handleDateChanged, onBlur]
  );

  const handlePickerChange = useCallback(
    (name: string, date: Date) => {
      date = applyDefaultHour(date);

      const pickerValue = getDisplayDate(date);

      /**
       * The date picker selection is triggered after the native input events are triggered. This causes
       * validation timing issues with the new input value. The following synthetically triggers the value
       * being set in the input (triggers handleChange above). Blur is called before rhf knows of the change so we
       * need the blur event to trigger again for rhf to have the correct value before triggering blur logic.
       */
      const valueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
      valueSetter?.call(anchorElRef.current, pickerValue);

      const syntheticChange = new Event("input", { bubbles: true });
      anchorElRef.current?.dispatchEvent(syntheticChange);

      anchorElRef.current?.focus();
      setTimeout(() => anchorElRef.current?.blur(), 10);
    },
    [applyDefaultHour, getDisplayDate]
  );

  const datePickerValue = parseFn((!value && defaultDatePickerValue ? defaultDatePickerValue : value) || "");

  return (
    <Box className={clsx(classes.root, className)}>
      <MuiTextField
        onChange={handleChange}
        name={name}
        onBlur={handleBlur}
        inputRef={(el) => {
          // Set the anchor ref and then trigger the inputRef
          // callback. RHF needs this el and the DateTimePicker.
          if (!anchorElRef.current) {
            anchorElRef.current = el;
          }

          switch (typeof inputRef) {
            case "function":
              inputRef(el);
              break;
            case "object":
              (inputRef as MutableRefObject<HTMLInputElement>).current = el;
              break;
          }
        }}
        {...textFieldPropsRest}
      />

      {/* TODO: (SS) Likely will need an option to not show the date picker in mobile? */}
      <DateTimePicker
        anchorEl={anchorElRef}
        value={datePickerValue}
        onChange={handlePickerChange}
        inlinePicker={inlinePicker}
        staticPicker={staticPicker}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    </Box>
  );
};

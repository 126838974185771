import { TextFieldProps as MuiTextFieldProps } from "@material-ui/core";
import { ParsingOption as ChronoParsingOption } from "chrono-node";
import { useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { IRhfControl } from ".";
import { DATE_DISPLAY_FORMAT } from "../../../utils/dates";
import { deepmerge } from "../../../utils/objects";
import { DateControl } from "../DateControl";
import { RhfControlled, RhfControlledProps, RhfControlledRenderProps } from "./RhfControlled";

export type RhfDateControlRenderProps = MuiTextFieldProps & RhfControlledRenderProps & {};

export type RhfDateControlProps = Omit<MuiTextFieldProps, "required"> &
  Omit<RhfControlledProps<FieldValues, RhfDateControlRenderProps>, "render"> & {
    format?: string;
    formatOnBlur?: boolean;
    refDate?: Date;
    chronoOptions?: ChronoParsingOption;
    required?: string | boolean;
    defaultDatePickerValue?: string;
    defaultDatePickerHour?: ((date: Date) => Date) | number;
    TextFieldClassName?: string;
    disablePast?: boolean;
    disableFuture?: boolean;
    inlinePicker?: boolean;
    staticPicker?: boolean;
    dayMode?: boolean;
  };

export const RhfDateControl: IRhfControl<RhfDateControlProps> = ({
  rules,
  format = DATE_DISPLAY_FORMAT,
  formatOnBlur = true,
  refDate,
  chronoOptions,
  required,
  helperText,
  defaultDatePickerValue,
  defaultDatePickerHour,
  TextFieldClassName,
  disablePast,
  disableFuture,
  inlinePicker,
  staticPicker,
  dayMode,
  className,
  ...rest
}) => {
  const mergedRules: RhfDateControlProps["rules"] = useMemo(
    () =>
      deepmerge({}, rules, {
        required,
        validate: {
          parseable: (v: string) => {
            console.log("=>", rest.name, v);
            return typeof v === "string" || "invalid date"
          },
        },
      }),
    [rules, required]
  );

  const render = useCallback(
    ({ field, fieldState, formState, ...rest }: RhfDateControlRenderProps) => {
      const { name, value, ref: inputRef, onChange } = field;

      return (
        <DateControl
          className={className}
          TextFieldProps={{
            ...rest,
            error: !!fieldState.error,
            helperText: !!fieldState.error?.message ? fieldState.error?.message : helperText,
          }}
          inputRef={inputRef}
          value={value}
          name={name}
          onDateChange={onChange}
          inlinePicker={inlinePicker}
          staticPicker={staticPicker}
          disablePast={disablePast}
          disableFuture={disableFuture}
        />
      );
    },
    [className, helperText, inlinePicker, staticPicker, disablePast, disableFuture]
  );

  return <RhfControlled {...rest} rules={mergedRules} render={render} />;
};

RhfDateControl.isControl = true;
RhfDateControl.isController = true;
